.container {
  display: flex;
  position: relative;
}

/** styles from country field */
.container > div {
  width: 36px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.container > div > select {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container > div > div {
  margin: 15px 0 0 12px;
}

/** styles from input field */
.container input {
  @apply border border-foreground/20 rounded-xl w-full pl-12 py-2.5;
  @apply focus:ring-0 focus:border-accent text-foreground bg-background focus:border-accent;
}

.error input {
  @apply border-error text-error focus:border-error/50 focus:ring-0;
}
